
import { Vue, Component } from "vue-property-decorator";
import {EmpreendimentoService} from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgBoleto extends Vue {

  filter: {
    id: number;
    numeroContrato: number;
    empreendimentoId: any;
    mesesVencimentoInicio: number;
    mesesVencimentoFim: number;
    empresaId:number;
    quantidadeParcelasMinima:number,
    quantidadeParcelasMaxima:number,
    diasVencimentoInicio:number,
    diasVencimentoFim:number
  } = {
    id: 0,
    numeroContrato: 0,
    empreendimentoId: 0,
    mesesVencimentoInicio: 0,
    mesesVencimentoFim:0,
    empresaId:0,
    quantidadeParcelasMinima:0,
    quantidadeParcelasMaxima:0,
    diasVencimentoInicio:0,
    diasVencimentoFim:0
  }; 

   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório de Contratos em Atraso', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  valid = true;
  empreendimentos: any = [];
  empresas: any = [];

  get selecionarTodosEmpreendimentos(){
    return this.filter.empreendimentoId.length === this.empreendimentos.length
  }

  get SelecionarAlgunsEmpreendimentos(){
    return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
  }

  get iconEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
    return 'Selecionar todos'
  }

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
        if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentoId = []
        } else {
          this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
        }
    })
  } 

  mounted() { 
    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
      res => {
        this.empresas = res.data.items;
      }
    );

    const empreendimentoService = new EmpreendimentoService();
    empreendimentoService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      (res) => {
        this.empreendimentos = res.data.items;
      }); 
  } 

  Visualizar() {
    if((!this.filter.mesesVencimentoInicio || !this.filter.mesesVencimentoFim) && (!this.filter.quantidadeParcelasMinima || !this.filter.quantidadeParcelasMaxima) && (!this.filter.diasVencimentoInicio || !this.filter.diasVencimentoFim)){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos um dos campos.", "warning");
        return true;
    }    
    let routeLink = this.$router.resolve({ 
      name: "RelContratoAtraso",
      query: {
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
        numeroContrato: this.filter.numeroContrato ? this.filter.numeroContrato.toString() : "",
        mesesVencimentoInicio: this.filter.mesesVencimentoInicio ? this.filter.mesesVencimentoInicio.toString() : "",
        mesesVencimentoFim: this.filter.mesesVencimentoFim ? this.filter.mesesVencimentoFim.toString() : "",
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
        quantidadeParcelasMinima: this.filter.quantidadeParcelasMinima ? this.filter.quantidadeParcelasMinima.toString() : "",
        quantidadeParcelasMaxima: this.filter.quantidadeParcelasMaxima ? this.filter.quantidadeParcelasMaxima.toString() : "",
        diasVencimentoInicio: this.filter.diasVencimentoInicio ? this.filter.diasVencimentoInicio.toString() : "",
        diasVencimentoFim: this.filter.diasVencimentoFim ? this.filter.diasVencimentoFim.toString() : "",
      },
    });
    window.open(routeLink.href, "_blank");
  }
}
